<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <base-material-card
          color="primary"
          icon="mdi-map-search"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar análise setorizada: {{ analise.nome }} ({{
                analise.numero_setores
              }}
              setores)
            </div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="ml-0 mt-4"
          >
            <v-col cols="12">
              <div id="visualizar-mapa-analise-setorizada"></div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col cols="3">
        <base-material-card
          color="primary"
          icon="mdi-information-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Mais informações</div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="ml-0 mt-4 mb-4"
          >
            <v-col cols="12">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Opacidade
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-slider
                      v-model="opacity"
                      min="0"
                      max="100"
                      thumb-label
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Setorização: {{ analise.setorizacao.nome }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      Setorizado por: {{ analise.setorizacao.algoritmo.nome }}
                    </div>
                    <div>
                      Descrição do algoritmo de análise setorizada:
                      {{ analise.setorizacao.algoritmo.descricao }}
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Bases de dados selecionadas: {{ basesSelecionadas }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>Fórmula: {{ analise.formula | toLowerCase }}</div>
                    <div>Mês dos dados: {{ analise.mes | formatToMonth }}</div>
                    <div>Versão da BDGD: {{ analise.versao }}</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import Gradient from 'javascript-color-gradient';
import GeoServerService from '@/services/GeoServerService';
import AnalisesSetorizadasService from '@/services/AnalisesSetorizadasService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import dayjs from 'dayjs';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [
    mapFeaturesStylesMixins,
    geocoderMixins,
    browserPrintMixins,
    drawMixins,
    filtersMixins
  ],

  data() {
    return {
      analise: {
        nome: '',
        numero_setores: 0,
        setorizacao: {
          nome: '',
          algoritmo: {
            nome: '',
            descricao: ''
          }
        },
        bases: [],
        formula: '',
        mes: '',
        versao: 0,
        cores: {
          corInicio: null,
          corFim: null
        }
      },
      resultadoAnalise: [],
      mapaId: 'visualizar-mapa-analise-setorizada',
      map: null,
      layers: [],
      infoControl: null,
      legendControl: null,
      layerControl: null,
      layerControlMaxLayers: 30,
      opacity: 80
    };
  },

  mounted() {
    this.configuraMapa(8);
    this.getAnaliseSetorizada(this.$route.params.id);
  },

  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },

    geoserverCqlFilter() {
      if (
        this.companyId &&
        this.analise &&
        this.analise.mes &&
        this.analise.versao
      ) {
        return this.getBasicCqlFilter(
          this.companyId,
          this.analise.mes,
          this.analise.versao
        );
      }
      return null;
    },

    geoserverOgcFilter() {
      if (
        this.companyId &&
        this.analise &&
        this.analise.mes &&
        this.analise.versao
      ) {
        return this.getBasicOgcFilter(
          this.companyId,
          this.analise.mes,
          this.analise.versao
        );
      }
      return null;
    },

    basesSelecionadas() {
      return this.analise.bases.join(', ');
    },

    viewsSelecionadas() {
      let views = {};
      this.analise.bases_dados_geograficas.forEach(
        (base) => (views[base.nome_tabela] = base.descricao)
      );
      return views;
    },

    selectedField() {
      return this.analise.campo.campo.trim().toUpperCase();
    },

    resultadoAnaliseValores() {
      let arr = this.resultadoAnalise.map(
        (resultado) => resultado.variavel_analise
      );
      return Array.from(new Set(arr));
    },

    startColor() {
      return this.analise.cores.corInicio;
    },

    finalColor() {
      return this.analise.cores.corFim;
    },

    midpoint() {
      return this.resultadoAnaliseValores.length;
    },

    colors() {
      if (!this.startColor || !this.finalColor || !this.midpoint) return [];

      let c = new Gradient()
        .setColorGradient(this.startColor, this.finalColor)
        .setMidpoint(this.midpoint)
        .getColors();

      let colors = {};
      this.resultadoAnaliseValores.forEach(
        (valor, index) => (colors[valor] = c[index])
      );

      return colors;
    }
  },

  methods: {
    getAnaliseSetorizada(id) {
      AnalisesSetorizadasService.getAnaliseSetorizada(id)
        .then((response) => {
          this.analise = response.data.data;
          this.setInfoControl();
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar a análise setorizada.', '', {
            position: 'topRight'
          })
        );
    },

    getResultadoAnaliseSetorizada(id) {
      AnalisesSetorizadasService.getResultadoAnaliseSetorizada(id)
        .then((response) => (this.resultadoAnalise = response.data))
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar o resultado da análise setorizada.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => this.map.spin(false));
    },

    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers(), null, {
        sortLayers: true
      });
      this.layerControl.addTo(this.map);

      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Resultado Análise Setorizada').addTo(
        this.map
      );
      this.configDrawControl(L);

      const [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;

      this.map.setView([latitude, longitude], zoom);
    },

    configDrawControl(L) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer: polygon }) => {
        drawnItems.addLayer(polygon);

        // Efetua o download dos arquivos com as feições dentro do poligono criado
        const arrLatLngs = polygon.getLatLngs();

        Object.keys(this.viewsSelecionadas).forEach((layerName) =>
          this.downloadCsvFeaturesWithinPolygon(layerName, arrLatLngs[0])
        );
      });

      this.map.on('draw:edited', ({ layers: polygons }) => {
        polygons.eachLayer((polygon) => {
          // Efetua o download dos arquivos com as feições dentro do poligono criado
          const arrLatLngs = polygon.getLatLngs();

          Object.keys(this.viewsSelecionadas).forEach((layerName) =>
            this.downloadCsvFeaturesWithinPolygon(layerName, arrLatLngs[0])
          );
        });
      });
    },

    downloadCsvFeaturesWithinPolygon(layerName, polygonLatLngs) {
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      const promise = this.isCqlFilter()
        ? GeoServerService.downloadCsvFeaturesWithinPolygon(
            layerName,
            polygonLatLngs,
            this.geoserverCqlFilter,
            0 // maxFeatures
          )
        : GeoServerService.downloadCsvFeaturesWithinPolygonUsingOgcFilter(
            layerName,
            polygonLatLngs,
            this.geoserverOgcFilter,
            0 // maxFeatures
          );

      promise
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `${layerName}_feicoes_selecionadas_${timestamp}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo CSV com as feições selecionadas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },

    getDadosAreaConcessao() {
      this.map.spin(true);

      return GeoServerService.getDadosLayer(
        'view_arat',
        'geom',
        this.isCqlFilter() ? this.geoserverCqlFilter : this.geoserverOgcFilter,
        '', // bbox
        5000, // maxFeatures
        'application/json', // outputFormat
        this.getFilterType()
      );
    },

    async centralizaMapa() {
      const response = await this.getDadosAreaConcessao();
      const geojson = response.data;

      let aratLayer = L.geoJSON(geojson, {
        style: () => {
          const color = '#EF9A9A';
          const fillColor = '#FFCDD2';
          const opacity = this.getMapFeatureOpacity;
          const fillOpacity = 0;

          return {
            color,
            fillColor,
            opacity,
            fillOpacity
          };
        }
      });
      this.map.fitBounds(aratLayer.getBounds());
      aratLayer.addTo(this.map);

      this.layerControl.addOverlay(aratLayer, 'ARAT');

      setTimeout(() => {
        this.addBaseLayers();
        this.getResultadoAnaliseSetorizada(this.$route.params.id);
      }, 1000);
    },

    addBaseLayers() {
      this.analise.bases_dados_geograficas.forEach((base) =>
        this.addBaseLayer(base)
      );
    },

    addBaseLayer(base) {
      const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
      const baseUrl = GeoServerService.getWmsBaseUrl();
      const workspace = GeoServerService.getWorkspaceDefault();

      const getProperties = (arrInfo) => {
        const ignoredProperties = [
          'GEOM',
          'BDGD_VERSAO_ID',
          'CONTADOR',
          'COMPANY',
          'COMPANY_ID'
        ];

        let arrProperties = [];

        arrInfo.forEach((element, key) => {
          if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
            return;

          if (element.slice(-4) == 'null') return;

          element = element.split('=');

          let property = element[0].trim().toUpperCase();
          if (ignoredProperties.includes(property)) return;

          arrProperties[property] = element[1].trim();
        });

        return arrProperties;
      };

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let properties = getProperties(arrInfo);

          let selectedField = this.selectedField;
          let value = parseFloat(properties[selectedField]).toLocaleString(
            'pt-BR'
          );

          let firstLine = arrInfo[0];
          let basename = this.getBasename(firstLine);

          let htmlPopup = `<h4>${basename}</h4><b>${selectedField.toLowerCase()}</b>: ${value}`;

          this.map.openPopup(htmlPopup, latlng);
        }
      });

      // Cria o layer
      let config = {
        format: 'image/png',
        transparent: true,
        identify: true // Funcionalidade de clicar na feição e obter os dados
      };

      this.isCqlFilter()
        ? (config.cql_filter = this.geoserverCqlFilter)
        : (config.filter = this.geoserverOgcFilter);

      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      let layer = source.getLayer(`${workspace}:${base.nome_tabela}`);

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(layer, base.descricao);
    },

    getBasename(firstLine) {
      let geoserverLayer = firstLine.split("'")[1];
      let view = geoserverLayer.split(':')[1];
      return this.viewsSelecionadas[view];
    },

    removeLayers() {
      this.layers.forEach((layer) => {
        this.map.removeLayer(layer);
        this.layerControl.removeLayer(layer);
      });
      this.layers = [];
    },

    plotaResultadoAnalise() {
      const zoomToFeature = (e) => this.map.fitBounds(e.target.getBounds());

      this.removeLayers();

      this.resultadoAnalise.forEach((resultado) => {
        const highlightFeature = (e) => {
          var layer = e.target;

          layer.setStyle({
            weight: 2,
            color: '#666',
            dashArray: '',
            fillOpacity: this.opacity / 100
          });

          if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
          }

          this.infoControl.update(resultado);
        };

        let layer;
        const resetHighlight = (e) => {
          layer.resetStyle(e.target);
          this.infoControl.update();
        };

        layer = L.geoJSON(JSON.parse(resultado.geojson), {
          style: () => {
            return {
              fillColor: this.colors[resultado.variavel_analise],
              weight: 2,
              opacity: 1,
              color: 'white',
              dashArray: '3',
              fillOpacity: this.opacity / 100
            };
          },

          onEachFeature: (feature, layer) => {
            layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: zoomToFeature
            });
          }
        });

        let msgPopup = this.msgPopup(resultado);
        if (msgPopup) layer.bindPopup(msgPopup);

        layer.addTo(this.map);
        this.layers.push(layer);

        if (this.analise.numero_setores <= this.layerControlMaxLayers) {
          this.layerControl.addOverlay(layer, resultado.setor);
        }
      });

      this.map.fitBounds(L.featureGroup(this.layers).getBounds());

      this.map.spin(false);
    },

    msgPopup(resultado) {
      let msg = `<h4>${resultado.setor}</h4>`;

      const atributos = JSON.parse(resultado.atributos);
      Object.keys(atributos).forEach((atributo) => {
        let dado = atributos[atributo];
        if (dado === '' || dado === null) return;
        msg += `<b>${atributo}:</b> ${dado}<br>`;
      });

      return msg;
    },

    setInfoControl() {
      this.infoControl = L.control();

      this.infoControl.onAdd = function () {
        this._div = L.DomUtil.create(
          'div',
          'visualizar-mapa-analise-setorizada-info'
        );
        this.update();
        return this._div;
      };

      const analise = this.analise;
      const obj = this;
      this.infoControl.update = function (props) {
        this._div.innerHTML =
          '<h4>Setor selecionado</h4>' +
          (props ? obj.getHtmlProps(analise, props) : 'Nenhum selecionado');
      };

      this.infoControl.addTo(this.map);
    },

    getHtmlProps(analise, props) {
      const formula = analise.formula.toLowerCase();
      const variavelAnalise = parseFloat(props.variavel_analise).toLocaleString(
        'pt-BR'
      );
      const numeroItens = parseInt(props.numero_itens).toLocaleString('pt-BR');

      return `
<b>${props.setor}</b><br>
<b>${formula}</b>: ${variavelAnalise}<br>
<b>nº de itens</b>: ${numeroItens}
`;
    },

    setLegendControl() {
      this.legendControl = L.control({ position: 'bottomleft' });

      let obj = this;
      this.legendControl.onAdd = function () {
        var div = L.DomUtil.create(
          'div',
          'visualizar-mapa-analise-setorizada-info visualizar-mapa-analise-setorizada-legend'
        );

        let html = '';

        obj.resultadoAnaliseValores.forEach((valor) => {
          let cor = obj.colors[valor];
          let v = parseFloat(valor).toLocaleString('pt-BR');
          html += `<i style="background:${cor}"></i> <= ${v}<br>`;
        });

        div.innerHTML = html;

        return div;
      };

      this.legendControl.addTo(this.map);
    }
  },

  watch: {
    geoserverCqlFilter() {
      this.centralizaMapa();
    },

    resultadoAnalise() {
      this.plotaResultadoAnalise();
    },

    resultadoAnaliseValores() {
      this.setLegendControl();
    },

    opacity() {
      this.plotaResultadoAnalise();
    }
  }
};
</script>

<style>
#visualizar-mapa-analise-setorizada {
  height: 75vh;
  z-index: 0;
}

.visualizar-mapa-analise-setorizada-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.visualizar-mapa-analise-setorizada-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.visualizar-mapa-analise-setorizada-legend {
  line-height: 18px;
  color: #555;
  max-height: 250px;
  overflow-y: auto;
}

.visualizar-mapa-analise-setorizada-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
